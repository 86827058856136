import dayjs from "dayjs";
import { COND_TYPE } from "../lib/bookingLogic/params";
import { datesFromRecurring, getNextRecurrTime } from "./datesFromRecurring";

export function filterEvents(event) {
  if (event?.recurrence?.recurring) {
    const recurringDates = datesFromRecurring(event, {
      start: new Date(),
      end: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
    if (recurringDates.length) {
      return true;
    }
  } else if (event.start?.toDate() > new Date()) {
    return true;
  } else {
    return false;
  }
}

export const convertTimetstamp = (date) => {
  if (date && date?.toDate) {
    return date.toDate().toISOString();
  }
  return date;
};

/*
 * Before sending all events, strip out the non-essential information & remove the venue if it's private.
 */
export const stripDownEvent = (event) => {
  const strippedEvent = {
    _id: event._id,
    name: event.name,
    bookable: event.bookable,
    bookingDetails: event.bookingDetails,
  };

  if (event?.recurrence) {
    strippedEvent.recurrence = event.recurrence;
  }

  if (event?.venue) {
    strippedEvent.venue = event.venue;
  }

  if (event?.start) {
    strippedEvent.start = convertTimetstamp(event.start);
  }

  if (event?.end) {
    strippedEvent.end = convertTimetstamp(event.end);
  }

  if (event?.concluded) {
    strippedEvent.concluded = convertTimetstamp(event.concluded);
  }

  if (event?.tags) {
    strippedEvent.tags = event?.tags;
  }

  return strippedEvent;
};

export const getSlotKey = (date, slotInfo) => {
  let key = `${date.start.toISOString()}`;
  if (slotInfo) {
    key = `${key}%${slotInfo.id}`;
  }
  return key;
};

export const getDateEventKey = (eventId, date, slotId) => {
  let key = `${eventId}-%-${date}`;
  
  if (slotId) {
    key = `${key}-%-${slotId}`;
  }

  return key;
};

export const eventAgeRestriction = (rulesData) => {
  if (rulesData) {
    return rulesData?.eligible?.indiv[0]?.conditions.filter(
      ({ feature, type }) =>
        feature === "age" &&
        type !== COND_TYPE.NOT_EQUALS &&
        type !== COND_TYPE.NOT_EQUALS_HACKY_FIX
    );
  }
};

export const ageConditionToText = (ageCondition) => {
  switch (ageCondition.type) {
    case COND_TYPE.BETWEEN:
      return `${ageCondition.min}-${ageCondition.max} years`;
    case COND_TYPE.MAX:
      return `<${ageCondition.value} years`;
    case COND_TYPE.MIN:
      return `${ageCondition.value}+ years`;
    default:
      return "";
  }
};

export const getEventTotalCapacity = (eventData) => {
  const { bookingDetails = {} } = eventData;
  let totalCapacity = bookingDetails?.capacityTotal;

  if (bookingDetails?.showTimeSlots && Array.isArray(bookingDetails?.timeSlots)) {
    totalCapacity = bookingDetails.timeSlots.reduce(
      (a, b) => a + b.capacity,
      0
    );
  }

  return totalCapacity;
};

export const isTimeSlotEvent = (event) => {
  const { bookingDetails = {} } = event;
  return bookingDetails?.showTimeSlots && Array.isArray(bookingDetails.timeSlots) && bookingDetails.timeSlots.length > 0;
}

export const isEventAfterNow = (event) => {
  if (!event?.recurrence?.recurring) {
    return dayjs(event.start.toDate()).isAfter(dayjs().startOf("day"));
  }

  if (event?.concluded) {
    return dayjs(event.concluded.toDate()).isAfter(dayjs().startOf("day"));
  }

  if (event?.recurrence?.type === "SPECIFIC") {
    const dates = event.recurrence?.dates?.map((d) => dayjs(d, "YYYY-MM-DD"));
    // if any date is past today, return true
    return dates?.some((d) => d.isAfter(dayjs().startOf("day")));
  }

  return true;
};

export const sortByNextOccurrence = (a, b) => {
  let aTimestamp = a.start;
  if (a.recurrence?.recurring) {
    aTimestamp = getNextRecurrTime(a) ?? aTimestamp;
  }
  let bTimestamp = b.start;
  if (b.recurrence?.recurring) {
    bTimestamp = getNextRecurrTime(b) ?? bTimestamp;
  }
  return aTimestamp - bTimestamp;
};

export default {
  filterEvents,
  stripDownEvent,
  getSlotKey,
  eventAgeRestriction,
  sortByNextOccurrence,
};
